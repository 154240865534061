var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageTitle", [_vm._v("Scrims")]),
      _c("ApiLoadingController", {
        ref: "loadingController",
        attrs: { fetch: _vm.getScrims },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var data = ref.data
              return [
                _c("Scrim2Table", {
                  attrs: {
                    items: data,
                    "sort-by": "created_at",
                    "sort-desc": true
                  },
                  on: { deleteScrim: _vm.deleteScrim }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
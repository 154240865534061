export function parseOWScrimExport(scrim) {
  let kills = []
  let players = []
  let rounds = []
  let match_id = scrim.matchInfo.match_id ? scrim.matchInfo.match_id : scrim.matchInfo.pseudo_match_id

  let match = {
    match_id: match_id,
    date: scrim.scrim_date,
    time: scrim.scrim_time,
    map: scrim.map.name,
    rounds_played: scrim.score.won + scrim.score.lost,
    team_score: scrim.score.won,
    opp_score: scrim.score.lost,
    win: scrim.score.won > scrim.score.lost,
    pistol_won: 0,
    pistol_atk_won: 0,
    pistol_def_won: 0,
    atk_won: 0,
    atk_lost: 0,
    def_won: 0,
    def_lost: 0,
    team_agents: [],
    opp_agents: [],
  }

  scrim.roster.forEach(r => {
    if (r.teammate) match.team_agents.push(r.agent)
    else match.opp_agents.push(r.agent)
  })

  match.team_agents.sort()
  match.team_agents = match.team_agents.join(',')
  match.opp_agents.sort()
  match.opp_agents = match.opp_agents.join(',')

  scrim.rounds.forEach(round => {
    if (round === null) return
    let round_num = round.round_num + 1
    let first = null

    let team = round.teamStats ? round.teamStats.find(r => r.is_self_team) : null
    let round_duration = round.phases.end.timestamp - round.phases.combat.timestamp

    let parsed_round = {
      match_id: match_id,
      map: scrim.map.name,
      round_num: round_num,
      round_id: `${match_id}RND${round_num}`,
      team_side: round.team_role,
      team_win: round.won,
      round_duration: round_duration,
      pistol: round_num == 1 || round_num == 13 ? true : false,
      team_loadout: team != null ? team.loadout_value : 0,
      planted: round.spike.planted != null,
      plant_round_time: round.spike.planted != null ? round.spike.planted - round.phases.combat.timestamp : null,
      defused: round.spike.defused != null,
      detonated: round.spike.detonated != null,
      team_first_kill: false,
      team_first_death: false,
      team_traded_fk: false,
      team_traded_fd: false,
    }

    if (parsed_round.pistol && round.won) {
      match.pistol_won += 1
      match[`pistol_${round.team_role}_won`] += 1
    }

    if (round.won) match[`${round.team_role}_won`] += 1
    else match[`${round.team_role}_lost`] += 1

    let parsed_player = {}
    round.playerStats.forEach(p => {
      if (!p.teammate) return
      parsed_player[p.player_id] = {
        match_id: match_id,
        map: scrim.map.name,
        round_num: round_num,
        round_win: round.won,
        round_id: `${match_id}RND${round_num}`,
        side: round.team_role,
        player: scrim.roster[p.roster_idx].name,
        player_id: p.player_id,
        agent: scrim.roster[p.roster_idx].agent,
        weapon: p.weapon ? WEAPONS[p.weapon].name : 'Classic',
        armor: p.armor ? ARMOR[p.armor].name : '',
        kills: p.kills,
        deaths: p.deaths,
        bomb_deaths: 0,
        assists: p.assists,
        first_kill: 0,
        first_death: 0,
        traded_first_kill: 0,
        traded_first_death: 0,
        trade_kills: 0,
        traded_deaths: 0,
        ult_count: p.ultimates.count,
        ult_max: p.ultimates.max,
        ult_ready: p.ultimates.count == p.ultimates.max,
      }
    })

    let parsed_kills = []
    round.killfeed.forEach((k, i) => {
      let event = 'type' in k ? k.type : 'kill'
      if (event === 'kill' && 'damage_agent' in k.finishing_damage && k.finishing_damage.damage_agent === 'Sage')
        event = 'revive'
      if (event === 'kill' && k.ult === 'TX_UI_Smonk_X') event = 'revive'

      if (event === 'revive') {
        parsed_kills.push({
          match_id: match_id,
          map: scrim.map.name,
          round_num: round_num,
          round_id: `${match_id}RND${round_num}`,
          kill_id: `${match_id}RND${round_num}K${i}`,
          round_time: k.round_time_millis,
          killer: k.attacker,
          killer_id: k.attacker_player_id,
          killer_teammate: k.is_attacker_teammate,
          killer_agent: scrim.roster[k.attacker_roster_idx].agent,
          killer_side: k.is_attacker_teammate ? round.team_role : OPP_ROLE[round.team_role],
          victim: k.victim,
          victim_id: k.victim_player_id,
          victim_teammate: k.is_victim_teammate,
          victim_agent: scrim.roster[k.victim_roster_idx].agent,
          victim_side: k.is_victim_teammate ? round.team_role : OPP_ROLE[round.team_role],
          is_self_kill: false,
          is_team_kill: false,
          is_bomb: false,
          is_fall: false,
          is_revive: true,
          first: false,
          damage_type: 'Revive',
          weapon: 'Revive',
          traded_by_kill_id: '',
          trade_for_kill_id: '',
          traded_player_id: '',
          traded_player: '',
        })
      }

      if (event != 'kill') return

      if (first === null) {
        first = k
        if (k.is_attacker_teammate && !k.is_victim_teammate) {
          if (k.attacker_player_id in parsed_player) parsed_player[k.attacker_player_id].first_kill += 1
          parsed_round.team_first_kill = true
        }
        if (k.is_victim_teammate) {
          if (k.victim_player_id in parsed_player) parsed_player[k.victim_player_id].first_death += 1
          parsed_round.team_first_death = true
        }
      }

      if (k.is_victim_teammate && k.finishing_damage.damage_type === 'Bomb') {
        parsed_player[k.victim_player_id].bomb_deaths += 1
      }

      parsed_kills.push({
        match_id: match_id,
        map: scrim.map.name,
        round_num: round_num,
        round_id: `${match_id}RND${round_num}`,
        kill_id: `${match_id}RND${round_num}K${i}`,
        round_time: k.round_time_millis,
        killer: k.attacker,
        killer_id: k.attacker_player_id,
        killer_teammate: k.is_attacker_teammate,
        killer_agent: scrim.roster[k.attacker_roster_idx].agent,
        killer_side: k.is_attacker_teammate ? round.team_role : OPP_ROLE[round.team_role],
        victim: k.victim,
        victim_id: k.victim_player_id,
        victim_teammate: k.is_victim_teammate,
        victim_agent: scrim.roster[k.victim_roster_idx].agent,
        victim_side: k.is_victim_teammate ? round.team_role : OPP_ROLE[round.team_role],
        is_self_kill: k.attacker == k.victim,
        is_team_kill: k.is_attacker_teammate == k.is_victim_teammate,
        is_bomb: k.finishing_damage.damage_type === 'Bomb',
        is_fall: k.finishing_damage.damage_type === 'Fall',
        is_revive: false,
        first: first == k,
        damage_type: k.finishing_damage.damage_type,
        weapon: parseWeapon(k.finishing_damage),
        traded_by_kill_id: '',
        trade_for_kill_id: '',
        traded_player_id: '',
        traded_player: '',
      })

      if (
        !parsed_kills[parsed_kills.length - 1].first &&
        !parsed_kills[parsed_kills.length - 1].is_self_kill &&
        !parsed_kills[parsed_kills.length - 1].is_team_kill &&
        !parsed_kills[parsed_kills.length - 1].is_bomb &&
        !parsed_kills[parsed_kills.length - 1].is_revive
      ) {
        let possible_trades = parsed_kills.filter(
          kill =>
            kill != parsed_kills[parsed_kills.length - 1] &&
            !kill.is_self_kill &&
            !kill.is_team_kill &&
            !kill.is_bomb &&
            !kill.is_revive &&
            parsed_kills[parsed_kills.length - 1].round_time - kill.round_time <= 3000 &&
            parsed_kills[parsed_kills.length - 1].victim_id === kill.killer_id
        )

        if (possible_trades.length) {
          let trade = possible_trades.pop()
          let traded_index = parsed_kills.findIndex(kill => kill == trade)
          parsed_kills[traded_index].traded_by_kill_id = parsed_kills[parsed_kills.length - 1].kill_id
          parsed_kills[parsed_kills.length - 1].trade_for_kill_id = trade.kill_id
          parsed_kills[parsed_kills.length - 1].traded_player_id = trade.victim_id
          parsed_kills[parsed_kills.length - 1].traded_player = trade.victim

          if (trade.victim_teammate && trade.victim_id in parsed_player)
            parsed_player[trade.victim_id].traded_deaths += 1
          if (parsed_kills[parsed_kills.length - 1].killer_teammate && k.attacker_player_id in parsed_player)
            parsed_player[k.attacker_player_id].trade_kills += 1
          if (trade.first && parsed_round.team_first_kill) {
            if (trade.killer_id in parsed_player) parsed_player[trade.killer_id].traded_first_kill += 1
            parsed_round.team_traded_fk = true
          }
          if (trade.first && parsed_round.team_first_death) {
            if (trade.victim_id in parsed_player) parsed_player[trade.victim_id].traded_first_death += 1
            parsed_round.team_traded_fd = true
          }
        }
      }
    })

    rounds.push(parsed_round)
    kills.push(...parsed_kills)
    players.push(...Object.values(parsed_player))
  })

  //turn it into csv to download
  return {
    match: [match],
    rounds: rounds,
    players: players,
    kills: kills,
  }
}

// Can be weapon, ability, or other type of damage
function parseWeapon(d) {
  if (d.damage_type === 'Weapon') {
    return WEAPONS[d.damage_item].name
  } else if (d.damage_type === 'Ability') {
    return AGENTS[d.damage_agent][d.damage_item].name
  } else {
    return d.damage_item
  }
}

const AGENTS = {
  'Omen': {
    name: 'Omen',
    Ability1: {
      slot: 'Ability1',
      name: 'Paranoia',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Dark Cover',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Shrouded Step',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'From the Shadows',
    },
  },
  'Brimstone': {
    name: 'Brimstone',
    Grenade: {
      slot: 'Grenade',
      name: 'Stim Beacon',
    },
    Ability1: {
      slot: 'Ability1',
      name: 'Incendiary',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Sky Smoke',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Orbital Strike',
    },
  },
  'Neon': {
    name: 'Neon',
    Ability2: {
      slot: 'Ability2',
      name: 'High Gear',
    },
    Ability1: {
      slot: 'Ability1',
      name: 'Relay Bolt',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Fast Lane',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Overdrive',
    },
  },
  'Sage': {
    name: 'Sage',
    Ability1: {
      slot: 'Ability1',
      name: 'Slow Orb',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Healing Orb',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Barrier Orb',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Resurrection',
    },
  },
  'Viper': {
    name: 'Viper',
    Ability1: {
      slot: 'Ability1',
      name: 'Poison Cloud',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Toxic Screen',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Snake Bite',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: "Viper's Pit",
    },
    Passive: {
      slot: 'Passive',
      name: 'Toxic',
    },
  },
  'Sova': {
    name: 'Sova',
    Ability1: {
      slot: 'Ability1',
      name: 'Shock Bolt',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Recon Bolt',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Owl Drone',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: "Hunter's Fury",
    },
    Passive: {
      slot: 'Passive',
      name: 'Uncanny Marksman',
    },
  },
  'Fade': {
    name: 'Fade',
    Ability1: {
      slot: 'Ability1',
      name: 'Seize',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Haunt',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Prowler',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Nightfall',
    },
  },
  'Reyna': {
    name: 'Reyna',
    Ability1: {
      slot: 'Ability1',
      name: 'Devour',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Dismiss',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Leer',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Empress',
    },
  },
  'Astra': {
    name: 'Astra',
    Ability1: {
      slot: 'Ability1',
      name: 'Nova Pulse',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Nebula  / Dissipate',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Gravity Well',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Astral Form / Cosmic Divide',
    },
    Passive: {
      slot: 'Passive',
      name: 'Astral Form',
    },
  },
  'KAY/O': {
    name: 'KAY/O',
    Grenade: {
      slot: 'Grenade',
      name: 'FRAG/ment',
    },
    Ability1: {
      slot: 'Ability1',
      name: 'FLASH/drive',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'ZERO/point',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'NULL/cmd',
    },
  },
  'Jett': {
    name: 'Jett',
    Ability1: {
      slot: 'Ability1',
      name: 'Updraft',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Tailwind',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Cloudburst',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Blade Storm',
    },
    Passive: {
      slot: 'Passive',
      name: 'Drift',
    },
  },
  'Cypher': {
    name: 'Cypher',
    Ability1: {
      slot: 'Ability1',
      name: 'Cyber Cage',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Spycam',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Trapwire',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Neural Theft',
    },
  },
  'Killjoy': {
    name: 'Killjoy',
    Grenade: {
      slot: 'Grenade',
      name: 'Nanoswarm',
    },
    Ability1: {
      slot: 'Ability1',
      name: 'ALARMBOT',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'TURRET',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Lockdown',
    },
  },
  'Raze': {
    name: 'Raze',
    Ability1: {
      slot: 'Ability1',
      name: 'Blast Pack',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Paint Shells',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Boom Bot',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Showstopper',
    },
  },
  'Skye': {
    name: 'Skye',
    Ability1: {
      slot: 'Ability1',
      name: 'Trailblazer',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Guiding Light',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Regrowth',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Seekers',
    },
  },
  'Breach': {
    name: 'Breach',
    Ability1: {
      slot: 'Ability1',
      name: 'Flashpoint',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Fault Line',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Aftershock',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Rolling Thunder',
    },
  },
  'Chamber': {
    name: 'Chamber',
    Ability2: {
      slot: 'Ability2',
      name: 'Rendezvous',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Trademark',
    },
    Ability1: {
      slot: 'Ability1',
      name: 'Headhunter',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Tour De Force',
    },
  },
  'Harbor': {
    name: 'Harbor',
    Ability1: {
      slot: 'Ability1',
      name: 'Cove',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Cascade',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'High Tide',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Reckoning',
    },
  },
  'Phoenix': {
    name: 'Phoenix',
    Grenade: {
      slot: 'Grenade',
      name: 'Blaze',
    },
    Ability1: {
      slot: 'Ability1',
      name: 'Curveball',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Hot Hands',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Run it Back',
    },
    Passive: {
      slot: 'Passive',
      name: 'Heating Up',
    },
  },
  'Yoru': {
    name: 'Yoru',
    Grenade: {
      slot: 'Grenade',
      name: 'FAKEOUT',
    },
    Ability1: {
      slot: 'Ability1',
      name: 'BLINDSIDE',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'GATECRASH',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'DIMENSIONAL DRIFT',
    },
  },
  'Gekko': {
    name: 'Gekko',
    Ability1: {
      slot: 'Ability1',
      name: 'Wingman',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Dizzy',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Mosh Pit',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Thrash',
    },
  },
  'Iso': {
    name: 'Iso',
    Ability1: {
      slot: 'Ability1',
      name: 'Undercut',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Kill Contract',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Double Tap',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Contingency',
    },
  },
  'Deadlock': {
    name: 'Deadlock',
    Ability1: {
      slot: 'Ability1',
      name: 'Sonic Sensor',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Barrier Mesh',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'GravNet',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Annihilation',
    },
  },
  'Clove': {
    name: 'Clove',
    Grenade: {
      slot: 'Grenade',
      name: 'Pick-me-up',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Ruse',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Not Dead Yet',
    },
    Ability1: {
      slot: 'Ability1',
      name: 'Meddle',
    },
  },
  'Vyse': {
    name: 'Vyse',
    Ability1: {
      slot: 'Ability1',
      name: 'Shear',
    },
    Ability2: {
      slot: 'Ability2',
      name: 'Arc Rose',
    },
    Grenade: {
      slot: 'Grenade',
      name: 'Razorvine',
    },
    Ultimate: {
      slot: 'Ultimate',
      name: 'Steel Garden',
    },
  },
}

const OPP_ROLE = {
  def: 'atk',
  atk: 'def',
}

const WEAPONS = {
  '42da8ccc-40d5-affc-beec-15aa47b42eda': {
    id: '42da8ccc-40d5-affc-beec-15aa47b42eda',
    name: 'Shorty',
    mlname: 'shorty',
    display_icon_url: 'https://media.valorant-api.com/weapons/42da8ccc-40d5-affc-beec-15aa47b42eda/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/42da8ccc-40d5-affc-beec-15aa47b42eda/killstreamicon.png',
    cost: 150,
  },
  'a03b24d3-4319-996d-0f8c-94bbfba1dfc7': {
    id: 'a03b24d3-4319-996d-0f8c-94bbfba1dfc7',
    name: 'Operator',
    mlname: 'operator',
    display_icon_url: 'https://media.valorant-api.com/weapons/a03b24d3-4319-996d-0f8c-94bbfba1dfc7/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/a03b24d3-4319-996d-0f8c-94bbfba1dfc7/killstreamicon.png',
    cost: 4700,
  },
  '63e6c2b6-4a8e-869c-3d4c-e38355226584': {
    id: '63e6c2b6-4a8e-869c-3d4c-e38355226584',
    name: 'Odin',
    mlname: 'odin',
    display_icon_url: 'https://media.valorant-api.com/weapons/63e6c2b6-4a8e-869c-3d4c-e38355226584/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/63e6c2b6-4a8e-869c-3d4c-e38355226584/killstreamicon.png',
    cost: 3200,
  },
  '55d8a0f4-4274-ca67-fe2c-06ab45efdf58': {
    id: '55d8a0f4-4274-ca67-fe2c-06ab45efdf58',
    name: 'Ares',
    mlname: 'ares',
    display_icon_url: 'https://media.valorant-api.com/weapons/55d8a0f4-4274-ca67-fe2c-06ab45efdf58/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/55d8a0f4-4274-ca67-fe2c-06ab45efdf58/killstreamicon.png',
    cost: 1600,
  },
  '9c82e19d-4575-0200-1a81-3eacf00cf872': {
    id: '9c82e19d-4575-0200-1a81-3eacf00cf872',
    name: 'Vandal',
    mlname: 'vandal',
    display_icon_url: 'https://media.valorant-api.com/weapons/9c82e19d-4575-0200-1a81-3eacf00cf872/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/9c82e19d-4575-0200-1a81-3eacf00cf872/killstreamicon.png',
    cost: 2900,
  },
  'ae3de142-4d85-2547-dd26-4e90bed35cf7': {
    id: 'ae3de142-4d85-2547-dd26-4e90bed35cf7',
    name: 'Bulldog',
    mlname: 'bulldog',
    display_icon_url: 'https://media.valorant-api.com/weapons/ae3de142-4d85-2547-dd26-4e90bed35cf7/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/ae3de142-4d85-2547-dd26-4e90bed35cf7/killstreamicon.png',
    cost: 2050,
  },
  'ee8e8d15-496b-07ac-e5f6-8fae5d4c7b1a': {
    id: 'ee8e8d15-496b-07ac-e5f6-8fae5d4c7b1a',
    name: 'Phantom',
    mlname: 'phantom',
    display_icon_url: 'https://media.valorant-api.com/weapons/ee8e8d15-496b-07ac-e5f6-8fae5d4c7b1a/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/ee8e8d15-496b-07ac-e5f6-8fae5d4c7b1a/killstreamicon.png',
    cost: 2900,
  },
  'ec845bf4-4f79-ddda-a3da-0db3774b2794': {
    id: 'ec845bf4-4f79-ddda-a3da-0db3774b2794',
    name: 'Judge',
    mlname: 'judge',
    display_icon_url: 'https://media.valorant-api.com/weapons/ec845bf4-4f79-ddda-a3da-0db3774b2794/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/ec845bf4-4f79-ddda-a3da-0db3774b2794/killstreamicon.png',
    cost: 1850,
  },
  '910be174-449b-c412-ab22-d0873436b21b': {
    id: '910be174-449b-c412-ab22-d0873436b21b',
    name: 'Bucky',
    mlname: 'bucky',
    display_icon_url: 'https://media.valorant-api.com/weapons/910be174-449b-c412-ab22-d0873436b21b/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/910be174-449b-c412-ab22-d0873436b21b/killstreamicon.png',
    cost: 850,
  },
  '44d4e95c-4157-0037-81b2-17841bf2e8e3': {
    id: '44d4e95c-4157-0037-81b2-17841bf2e8e3',
    name: 'Frenzy',
    mlname: 'frenzy',
    display_icon_url: 'https://media.valorant-api.com/weapons/44d4e95c-4157-0037-81b2-17841bf2e8e3/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/44d4e95c-4157-0037-81b2-17841bf2e8e3/killstreamicon.png',
    cost: 450,
  },
  '29a0cfab-485b-f5d5-779a-b59f85e204a8': {
    id: '29a0cfab-485b-f5d5-779a-b59f85e204a8',
    name: 'Classic',
    mlname: 'classic',
    display_icon_url: 'https://media.valorant-api.com/weapons/29a0cfab-485b-f5d5-779a-b59f85e204a8/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/29a0cfab-485b-f5d5-779a-b59f85e204a8/killstreamicon.png',
    cost: 0,
  },
  '1baa85b4-4c70-1284-64bb-6481dfc3bb4e': {
    id: '1baa85b4-4c70-1284-64bb-6481dfc3bb4e',
    name: 'Ghost',
    mlname: 'ghost',
    display_icon_url: 'https://media.valorant-api.com/weapons/1baa85b4-4c70-1284-64bb-6481dfc3bb4e/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/1baa85b4-4c70-1284-64bb-6481dfc3bb4e/killstreamicon.png',
    cost: 500,
  },
  '4ade7faa-4cf1-8376-95ef-39884480959b': {
    id: '4ade7faa-4cf1-8376-95ef-39884480959b',
    name: 'Guardian',
    mlname: 'guardian',
    display_icon_url: 'https://media.valorant-api.com/weapons/4ade7faa-4cf1-8376-95ef-39884480959b/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/4ade7faa-4cf1-8376-95ef-39884480959b/killstreamicon.png',
    cost: 2250,
  },
  'c4883e50-4494-202c-3ec3-6b8a9284f00b': {
    id: 'c4883e50-4494-202c-3ec3-6b8a9284f00b',
    name: 'Marshal',
    mlname: 'marshal',
    display_icon_url: 'https://media.valorant-api.com/weapons/c4883e50-4494-202c-3ec3-6b8a9284f00b/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/c4883e50-4494-202c-3ec3-6b8a9284f00b/killstreamicon.png',
    cost: 950,
  },
  '462080d1-4035-2937-7c09-27aa2a5c27a7': {
    id: '462080d1-4035-2937-7c09-27aa2a5c27a7',
    name: 'Spectre',
    mlname: 'spectre',
    display_icon_url: 'https://media.valorant-api.com/weapons/462080d1-4035-2937-7c09-27aa2a5c27a7/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/462080d1-4035-2937-7c09-27aa2a5c27a7/killstreamicon.png',
    cost: 1600,
  },
  'f7e1b454-4ad4-1063-ec0a-159e56b58941': {
    id: 'f7e1b454-4ad4-1063-ec0a-159e56b58941',
    name: 'Stinger',
    mlname: 'stinger',
    display_icon_url: 'https://media.valorant-api.com/weapons/f7e1b454-4ad4-1063-ec0a-159e56b58941/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/f7e1b454-4ad4-1063-ec0a-159e56b58941/killstreamicon.png',
    cost: 950,
  },
  '2f59173c-4bed-b6c3-2191-dea9b58be9c7': {
    id: '2f59173c-4bed-b6c3-2191-dea9b58be9c7',
    name: 'Melee',
    mlname: 'melee',
    display_icon_url: 'https://media.valorant-api.com/weapons/2f59173c-4bed-b6c3-2191-dea9b58be9c7/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/2f59173c-4bed-b6c3-2191-dea9b58be9c7/killstreamicon.png',
    cost: 0,
  },
  'e336c6b8-418d-9340-d77f-7a9e4cfe0702': {
    id: 'e336c6b8-418d-9340-d77f-7a9e4cfe0702',
    name: 'Sheriff',
    mlname: 'sheriff',
    display_icon_url: 'https://media.valorant-api.com/weapons/e336c6b8-418d-9340-d77f-7a9e4cfe0702/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/e336c6b8-418d-9340-d77f-7a9e4cfe0702/killstreamicon.png',
    cost: 800,
  },
  '5f0aaf7a-4289-3998-d5ff-eb9a5cf7ef5c': {
    id: '5f0aaf7a-4289-3998-d5ff-eb9a5cf7ef5c',
    name: 'Outlaw',
    mlname: 'outlaw',
    display_icon_url: 'https://media.valorant-api.com/weapons/5f0aaf7a-4289-3998-d5ff-eb9a5cf7ef5c/displayicon.png',
    kill_stream_icon_url:
      'https://media.valorant-api.com/weapons/5f0aaf7a-4289-3998-d5ff-eb9a5cf7ef5c/killstreamicon.png',
    cost: 2400,
  },
}

const ARMOR = {
  '822bcab2-40a2-324e-c137-e09195ad7692': {
    id: '822bcab2-40a2-324e-c137-e09195ad7692',
    name: 'Heavy',
    mlname: 'heavy',
    display_icon_url: 'https://media.valorant-api.com/gear/822bcab2-40a2-324e-c137-e09195ad7692/displayicon.png',
    cost: 1000,
  },
  '4dec83d5-4902-9ab3-bed6-a7a390761157': {
    id: '4dec83d5-4902-9ab3-bed6-a7a390761157',
    name: 'Light',
    mlname: 'light',
    display_icon_url: 'https://media.valorant-api.com/gear/4dec83d5-4902-9ab3-bed6-a7a390761157/displayicon.png',
    cost: 400,
  },
}

<template>
  <AppTable ref="table" :fields="fields" :items="computedItems" primary-key="id" v-bind="$attrs" v-on="$listeners">
    <template #cell(interact)="{ item }">
      <label>
        <input type="checkbox" :disabled="item.status" :checked="isSelected(item)" @change="toggleSelected(item)" />
      </label>
    </template>

    <template #cell(id)="{ item }">
      <div v-if="deleteConfirmations[item.id]">
        <span>Do you want to delete the scrim?</span>
        <div class="mt-1">
          <button role="button" class="btn btn-primary mr-2" @click="hideDeleteConfirmation(item)">No</button>
          <button role="button" class="btn btn-warning ml-2" @click="deleteScrim(item)">Yes</button>
        </div>
      </div>
      <div v-else>
        <div class="mb-2">{{ item.id }}</div>
        <router-link v-if="false" class="btn btn-outline-primary action action--stats" :to="`/scrim/${item.id}/edit`">
          <b-icon-pencil></b-icon-pencil> Edit
        </router-link>
        <button
          role="button"
          class="btn btn-outline-primary action action--stats"
          @click="showDeleteConfirmation(item)"
        >
          <b-icon-trash></b-icon-trash> Delete
        </button>
      </div>
    </template>

    <template #cell(created_at)="{ item }">
      <div class="column">
        <ScrimVodStatusIndicator :match-id="item.id" />
        <time :datetime="item.created_at | isoString" class="column">
          <span>{{ item.created_at | localDate }}</span>
          <span>{{ item.created_at | localTime }}</span>
        </time>
      </div>
    </template>

    <template #cell(map_id)="{ item }">
      <MapCell :id="item.map_id" v-if="item.map_id" variant="col" size="lg" />
      <span v-else class="no-data">N/A</span>
    </template>

    <template #cell(team1)="{ item }">
      <div class="column">
        <TeamCell v-bind="item.teams[0]" variant="row" />
        <AgentComposition
          :agents="item.teams[0].composition || []"
          :highlight="highlightAgentsTeam1"
          :variant="item.teams[0].team_side"
          size="sm"
        />
      </div>
    </template>

    <template #cell(score)="{ item }">
      <div class="score">
        <span class="score__result" :class="`score__result--${item.teams[0].win ? 'win' : 'loss'}`">{{
          item.teams[0].rounds_won
        }}</span>
        <span class="score__separator">:</span>
        <span class="score__result" :class="`score__result--${item.teams[1].win ? 'win' : 'loss'}`">{{
          item.teams[1].rounds_won
        }}</span>
      </div>
    </template>

    <template #cell(team2)="{ item }">
      <div class="column">
        <TeamCell v-bind="item.teams[1]" variant="row" />
        <AgentComposition
          :agents="item.teams[1].composition || []"
          :highlight="highlightAgentsTeam2"
          :variant="item.teams[1].team_side"
          size="sm"
        />
      </div>
    </template>

    <template #cell(total_rounds)="{ item }">
      <template>
        {{ item.total_rounds }}
      </template>
    </template>

    <template #cell(diff)="{ item }">
      <template>
        {{ Math.abs(item.teams[0].rounds_won - item.teams[1].rounds_won) }}
      </template>
    </template>

    <template #cell(actions)="{ item }">
      <template>
        <div class="column">
          <ScrimButtons :match-id="item.id" />
          <button
            class="tabe__btn-action tabe__btn-action--map mt-2"
            style="width: 100px; margin-left: auto; margin-right: auto"
            @click="getScrimExport(item)"
          >
            Export
          </button>
        </div>
      </template>
    </template>

    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </AppTable>
</template>

<script>
import { BIconPencil, BIconTrash } from 'bootstrap-vue'
import { format } from 'date-fns'
import px from 'vue-types'
import { mapGetters } from 'vuex'
import XLSX from 'xlsx'

import { getMatchExtraData } from '@/api/val-scrims'
import ScrimButtons from '@/components/scrim2/ScrimButtons.vue'
import ScrimVodStatusIndicator from '@/components/scrim2/ScrimVodStatusIndicator.vue'
import { parseOWScrimExport } from '@/utils/processScrimExport'

import isoString from '../../filters/isoString.js'
import localDate from '../../filters/localDate.js'
import localTime from '../../filters/localTime.js'
import mixpanel from '../../mixpanel.js'
import AppTable from '../generic/Table.vue'
import MapCell from '../Table/cells/MapCell.vue'
import TeamCell from '../Table/cells/TeamCell.vue'
import AgentComposition from '../UI/AgentComposition.vue'

export default {
  name: 'Scrim2Table',
  components: {
    AgentComposition,
    TeamCell,
    MapCell,
    AppTable,
    BIconPencil,
    BIconTrash,
    ScrimVodStatusIndicator,
    ScrimButtons,
  },
  props: {
    highlightAgentsTeam1: px.oneOfType([px.objectOf(px.bool), px.arrayOf(px.string)]),
    highlightAgentsTeam2: px.oneOfType([px.objectOf(px.bool), px.arrayOf(px.string)]),
    items: px
      .oneOfType([
        px.func,
        px.arrayOf(
          px.shape({
            id: px.string.isRequired,
          }).loose
        ),
      ])
      .isRequired.def([]),
    selected: px.arrayOf(px.shape({ id: px.string.isRequired }).loose),
    trackingContext: px.object.def({}),
  },
  data() {
    return {
      deleteConfirmations: {},
    }
  },
  computed: {
    ...mapGetters({
      getMapById: 'static/getMapById',
    }),

    computedItems() {
      return this.items.map(item => {
        const teamAAgentIds = item.teams[0].agent_ids
        const teamBAgentIds = item.teams[1].agent_ids
        return {
          ...item,
          teams: item.teams.map(team => {
            return {
              ...team,
              composition: team.agent_ids.map(id => {
                return {
                  id,
                  intersecting: teamAAgentIds.includes(id) && teamBAgentIds.includes(id),
                }
              }),
            }
          }),
        }
      })
    },

    fields() {
      return [
        {
          key: 'interact',
          label: '',
        },
        { key: 'id', label: 'Title' },
        {
          key: 'created_at',
          label: 'Date',
          formatter: localDate,
          sortable: true,
        },
        { key: 'map_id', label: 'Map' },
        {
          key: 'team1',
          label: 'Team 1',
        },
        { key: 'score', label: 'vs', tdClass: 'cell--score' },
        {
          key: 'team2',
          label: 'Team 2',
        },
        {
          key: 'total_rounds',
          label: 'Rounds',
          sortable: true,
        },
        {
          key: 'diff',
          label: 'Diff',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ]
    },

    selectedIds() {
      return Object.freeze(
        this.selected ? Object.fromEntries(this.selected.map((item, index) => [item.id, index])) : {}
      )
    },
  },
  filters: {
    localDate,
    localTime,
    isoString,
  },
  methods: {
    async getScrimExport(scrim) {
      const extraData = await getMatchExtraData(scrim.id)
      if (extraData?.finalData) {
        let parsed_export
        try {
          let date = Date.parse(scrim.created_at)
          parsed_export = parseOWScrimExport({
            ...extraData.finalData,
            scrim_date: format(date, 'MMM d, yyyy'),
            scrim_time: format(date, 'h:mm a'),
          })
        } catch (e) {
          throw new Error('Parsing Error')
        }
        const workbook = XLSX.utils.book_new()
        const match_worksheet = XLSX.utils.json_to_sheet(parsed_export.match)
        const rounds_worksheet = XLSX.utils.json_to_sheet(parsed_export.rounds)
        const players_worksheet = XLSX.utils.json_to_sheet(parsed_export.players)
        const kills_worksheet = XLSX.utils.json_to_sheet(parsed_export.kills)
        XLSX.utils.book_append_sheet(workbook, match_worksheet, 'Match')
        XLSX.utils.book_append_sheet(workbook, rounds_worksheet, 'Rounds')
        XLSX.utils.book_append_sheet(workbook, players_worksheet, 'Round Player')
        XLSX.utils.book_append_sheet(workbook, kills_worksheet, 'Kills')
        XLSX.writeFile(
          workbook,
          `${this.getMapById(scrim.map_id).name} - ${parsed_export.match[0].date} - ${scrim.id}.xlsx`
        )
      }
    },
    getTrackingContext(item) {
      return {
        ...this.trackingContext,
        scrimId: item.id,
      }
    },
    isSelected(item) {
      return item && this.selectedIds[item.id] >= 0
    },
    toggleSelected(item) {
      if (this.isSelected(item)) {
        const idx = this.selectedIds[item.id]
        const newSelected = [].concat(this.selected.slice(0, idx), this.selected.slice(idx + 1))
        this.$emit('update:selected', newSelected)
        mixpanel.track_deselect({ type: 'scrim', id: item.id, selected: newSelected }, this.getTrackingContext(item))
      } else {
        const newSelected = [].concat(this.selected, [item])
        this.$emit('update:selected', newSelected)
        mixpanel.track_select({ type: 'scrim', id: item.id, selected: newSelected }, this.getTrackingContext(item))
      }
    },
    trackStatsClick($event, item, ctx = this.getTrackingContext(item)) {
      mixpanel.track_link({ type: 'scrim', href: $event.target.href }, ctx)
    },
    trackMapClick($event, item, ctx = this.getTrackingContext(item)) {
      mixpanel.track_link({ type: 'maptool', href: $event.target.href }, ctx)
    },
    showDeleteConfirmation(item) {
      this.$set(this.deleteConfirmations, item.id, true)
    },
    hideDeleteConfirmation(item) {
      this.$set(this.deleteConfirmations, item.id, false)
    },
    deleteScrim(item) {
      this.hideDeleteConfirmation(item)
      this.$emit('deleteScrim', item)
    },
  },
}
</script>

<style lang="scss">
.column {
  display: flex;
  flex-flow: column nowrap;

  &--right {
    align-items: flex-end;
  }
}

.score {
  height: 100%;
  display: block;
  text-align: center;
  white-space: nowrap;

  &__result {
    font-weight: 700;
    font-size: 30px;
    line-height: 1;
    display: inline-block;
    width: 1.5em;

    &:first-of-type {
      text-align: right;
    }

    &:last-of-type {
      text-align: left;
    }

    &--win {
      color: $highlight;
    }
  }

  &__separator {
    font-weight: 400;
    font-size: 30px;
    line-height: 0.8;
    min-width: 0.75em;
    display: inline-block;
  }
}

.cell {
  &--score {
    text-align: center;
  }
}

.action {
  color: $white;
  white-space: nowrap;
}

.no-data {
  text-align: center;
  padding: 1rem;
  color: $red;
}

.tabe {
  width: 100%;
  position: relative;

  &__btn {
    &:hover {
      .tabe__header-value {
        color: $primary;
      }
    }
  }

  &__btn-action {
    padding: 0 2%;
    min-width: 100px;
    height: 32px;
    border: 1px solid $sc-3;
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
    color: $white;
    line-height: 1;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    // fix for vertical align of content
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover {
      background-color: $sc-3;
      color: color-yiq($sc-3);
    }

    &--vod {
      margin: 0 0.5em;
      min-width: calc(100px - 1em);
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }
}
</style>

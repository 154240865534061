var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiLoadingController", {
    attrs: { fetch: _vm.fetch, params: _vm.params },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          return [
            _c(
              data.hasInferences ? "router-link" : "span",
              {
                tag: "router-link",
                staticClass: "tabe__btn-action tabe__btn-action--map",
                class: { disabled: !data.hasInferences },
                attrs: { to: "/scrims2/map/" + _vm.matchId }
              },
              [_vm._v(" 2D Map ")]
            )
          ]
        }
      },
      {
        key: "loading",
        fn: function() {
          return [
            _c(
              "span",
              {
                staticClass: "tabe__btn-action tabe__btn-action--map disabled",
                attrs: { to: "/scrims2/map/" + _vm.matchId }
              },
              [_c("MiniSpinner", { staticClass: "mr-2" }), _vm._v(" 2D Map ")],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }